/* Active panel */
.panel-enter {
  opacity: 0 !important;
  z-index: -1 !important;
  transform: translateX(100%);
}
.panel-enter-active {
  opacity: 1 !important;
  z-index: 5 !important;
  transform: translateX(0%);
}
.panel-exit {
  opacity: 1 !important;
  z-index: 5 !important;
  transform: translateX(0%);
}
.panel-exit-active {
  opacity: 0 !important;
  z-index: -1 !important;
  transform: translateX(100%);
}
.panel-enter-active,
.panel-exit-active {
  transition: opacity 300ms, transform 300ms, z-index 300ms;
}

.panel-enter-done {
  opacity: 1 !important;
  z-index: 5 !important;
  transform: translateX(0%);
}

.panel-exit-done {
  opacity: 0 !important;
  z-index: -1 !important;
  transform: translateX(100%);
}
