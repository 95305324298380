body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input[placeholder] {
  text-overflow: ellipsis;
}
::-moz-placeholder {
  text-overflow: ellipsis;
} /* firefox 19+ */
input:-moz-placeholder {
  text-overflow: ellipsis;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "GothamRoundedBook";
  src: local("GothamRoundedBook"),
    url("./assets/fonts/GothamRounded-Book.otf") format("OpenType");
  font-weight: 400;
}

@font-face {
  font-family: "GothamRoundedLight";
  src: local("GothamRoundedLight"),
    url("./assets/fonts/GothamRounded-Light.otf") format("OpenType");
  font-weight: 300;
}

@font-face {
  font-family: "GothamRoundedMedium";
  src: local("GothamRounded-Medium"),
    url("./assets/fonts/GothamRounded-Medium.otf") format("OpenType");
  font-weight: 500;
}

@font-face {
  font-family: "GothamRoundedBold";
  src: local("GothamRoundedBold"),
    url("./assets/fonts/GothamRounded-Bold.otf") format("OpenType");
  font-weight: 700;
}

/*Tandem fonts */
@font-face {
  font-family: "ProximaNova";
  src: local("ProximaNova"),
    url("./assets/fonts/tandem/ProximaNova.otf") format("OpenType");
  font-weight: 400;
}

@font-face {
  font-family: "ProximaNovaMedium";
  src: local("ProximaNovaMedium"),
    url("./assets/fonts/tandem/ProximaNovaMedium.otf") format("OpenType");
  font-weight: 500;
}

@font-face {
  font-family: "ProximaNovaBold";
  src: local("ProximaNovaBold"),
    url("./assets/fonts/tandem/ProximaNovaBold.otf") format("OpenType");
  font-weight: 700;
}
