.transition {
  overflow-x: hidden;
}
.transition-enter {
  opacity: 0;
  transform: translateX(100%);
}
.transition-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: all 200ms ease-in;
}
.transition-exit {
  opacity: 1;
  transform: translateX(0);
}
.transition-exit-active {
  opacity: 0;
  transform: translateX(-100%);
  transition: all 200ms ease-in;
}
